import React, { useEffect, useRef, useMemo } from 'react';
import { View, StyleSheet } from 'react-native';
import { BText, DataGrid, Page } from '../../components';
import { Button, Input, InputRefProps, ButtonContainer } from '../../components/Form';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { setLoading } from '../../store/portal/portalActions';
import { fetchTasks, fetchTriggeredBy, fetchUsers } from '../../store/jira/jiraActions';

export const Home = ({ navigation }: any) => {
  const dispatch = useAppDispatch();
  const rtJira = useAppSelector(s => s.jira);

  const startYear = 2023;

  const yearList = useMemo(() => {
    const years = Array.from(new Array(new Date().getFullYear() - startYear + 1), (_, i) => startYear + i);
    return years.map(r => {
      return {
        label: r,
        value: r,
      };
    });
  }, []);

  const monthList = useMemo(
    () => [
      { label: 'January', value: 1 },
      { label: 'February', value: 2 },
      { label: 'March', value: 3 },
      { label: 'April', value: 4 },
      { label: 'May', value: 5 },
      { label: 'June', value: 6 },
      { label: 'July', value: 7 },
      { label: 'August', value: 8 },
      { label: 'September', value: 9 },
      { label: 'October', value: 10 },
      { label: 'November', value: 11 },
      { label: 'December', value: 12 },
    ],
    [],
  );

  const yearRef = useRef<InputRefProps>(null);
  const monthRef = useRef<InputRefProps>(null);
  const userRef = useRef<HTMLSelectElement>(null);
  const triggeredByRef = useRef<HTMLSelectElement>(null);

  useEffect(() => {
    if (!rtJira.userLoaded) {
      dispatch(fetchUsers());
    }

    if (!rtJira.triggeredLoaded) {
      dispatch(fetchTriggeredBy());
    }

    yearRef.current?.setValue(new Date().getFullYear());
    monthRef.current?.setValue(new Date().getMonth() + 1);
  }, []);

  useEffect(() => {
    if (rtJira.loaded) {
      dispatch(setLoading(false));
    }
  }, [rtJira.tasks, rtJira.loaded]);

  const handleSearch = () => {
    if (
      yearRef.current?.value &&
      monthRef.current?.value &&
      (userRef.current?.value || triggeredByRef.current?.value)
    ) {
      dispatch(setLoading(true));
      dispatch(
        fetchTasks(
          yearRef.current?.value,
          monthRef.current?.value,
          userRef.current?.value,
          triggeredByRef.current?.value,
        ),
      );
    }
  };

  return (
    <Page title={''}>
      <View style={homeStyle.containerMd}>
        <Input type="select" label="Year" dataSource={yearList} ref={yearRef} />
        <Input type="select" label="Month" dataSource={monthList} ref={monthRef} />
        <Input type="select" label="Assignee" dataSource={rtJira.users} ref={userRef} />
        <View>
          <BText>~~ UNION ALL ~~</BText>
        </View>
        <Input type="select" label="Triggered By" dataSource={rtJira.triggeredBy} ref={triggeredByRef} />
        <ButtonContainer>
          <Button onPress={handleSearch}>Search</Button>
        </ButtonContainer>
        <DataGrid
          data={rtJira.tasks}
          columns={[
            { key: 'User', label: 'Assignee' },
            { key: 'Ticket', label: 'Ticket' },
            { key: 'Summary', label: 'Summary' },
            { key: 'Status', label: 'Status' },
            { key: 'Remarks', label: 'Remarks' },
            { key: 'TriggeredBy', label: 'Triggered By' },
            { key: 'TriggerType', label: 'Type' },
            { key: 'CreatedDate', label: 'Date' },
          ]}
        />
      </View>
    </Page>
  );
};

const homeStyle = StyleSheet.create({
  containerMd: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginHorizontal: -8,
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  },
  containerSm: {
    flex: 1,
    flexDirection: 'column',
    marginHorizontal: -8,
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  },
});
