import { StyleSheet } from 'react-native';
import { themeConfig } from './themeConfig';

export const themeForm = StyleSheet.create({
  // Form Box
  container: {
    backgroundColor: themeConfig.colors.white,
    padding: 16,
    borderRadius: themeConfig.settings.borderRadius,
    margin: 'auto',
  },
  width400: {
    width: 432,
  },
  center: {
    margin: 'auto',
  },

  // Form Title
  formTitle: {
    ...themeConfig.text.title,
    fontWeight: 'bold',
    color: themeConfig.colors.darkGrey,
  },

  // Field Group
  fieldGroupContainer: {
    //backgroundColor: themeConfig.colors.white,
    //padding: 16,
    //borderRadius: themeConfig.settings.borderRadius,
    paddingTop: 8,
    marginTop: 16,
    marginBottom: 16,
    borderColor: themeConfig.colors.lightGrey,
    borderTopWidth: 1,
    borderBottomWidth: 1,
  },
  fieldGroupTitle: {
    ...themeConfig.text.subTitle,
    fontWeight: 'bold',
    color: themeConfig.colors.darkGrey,
  },

  // Field Row & Details
  fieldRow: {
    width: '100%',
    marginVertical: 8,
    marginHorizontal: 0,
    flexDirection: 'row',
  },
  fieldLabel: {
    width: 150,
    paddingTop: 8,
    flex: 1,
    lineHeight: 20,
    ...themeConfig.text.normal,
  },
  fieldContainer: {
    width: 350,
    minHeight: 36,
    flex: 3,
    ...themeConfig.text.normal,
  },

  // Extra information
  inputDescription: {
    //flex: 0 0 100%;
    //display: block;
    fontWeight: '100',
    fontSize: 13,
    color: themeConfig.colors.darkGrey,
  },
  inputError: {
    //flex: 0 0 100%;
    //  display: block;
    fontWeight: '500',
    fontSize: 13,
    color: themeConfig.colors.error,
  },

  // Input Styles
  textInput: {
    // Please update the style to textInputHMTL
    flex: 1,
    width: '100%',
    height: 26,
    minHeight: 26,
    fontSize: 14,
    paddingVertical: 4,
    paddingHorizontal: 10,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 10,
    paddingRight: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: themeConfig.settings.borderRadius,

    //      &:read-only {
    //        background-color: $booking-light-gray;
    //      }
    //}
  },
  picker: {},

  // Buttons
  /*buttonRowContainer: {
    width: '100%',
    button {
      width: 100%;

      > div {
        width: 100%;
      }
    }
  },*/

  // HTML Styles
  selectHTML: {
    width: '100%',
    height: 36,
    minHeight: 26,
    fontSize: 14,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 10,
    paddingRight: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: themeConfig.settings.borderRadius,
  },

  // Text Container
  textContainer: {
    flex: 1,
  },
  textContainerHorizontal: {
    flexDirection: 'row',
  },
  textContainerVertical: {
    flexDirection: 'column',
  },
  textContainerAlignCenter: {
    justifyContent: 'center',
  },
  textContainerAlignRight: {
    justifyContent: 'flex-end',
  },
});

// Html Input Style functions
export const textInputHMTLStyle = (width: number, readonly: boolean = false) => {
  return {
    flex: 1,
    width: width - 20,
    height: 26,
    minHeight: 26,
    fontSize: 14,
    paddingVertical: 4,
    paddingHorizontal: 10,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 10,
    paddingRight: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: themeConfig.settings.borderRadius,
    backgroundColor: readonly ? themeConfig.colors.lightGrey : '',
  };
};
