/**
 * @param {useToast} toast - the variable from useToast (from react-native-toast-notifications)
 * @param {ToastProps} data - data for toast (message, [title], [type])
 */

import React /*, { useEffect }*/ from 'react';
import { View, Text, TouchableOpacity, ViewStyle, TextStyle } from 'react-native';
//import { useToast } from 'react-native-toast-notifications';

export type ToastType = 'normal' | 'info' | 'success' | 'warning' | 'error';
export type ToastProps = {
  message?: string;
  title?: string;
  type?: ToastType;
};

// Function
export const showToast = (toast: any, data: ToastProps) => {
  const message: string = data.message || '';
  const title: string = data.title || '';
  const type: string = data.type || 'info';
  toast.show(message, { type: type, data: { title: title } });
};

/*
export const Toast = ({ message = '', title = '', type = 'info' }: ToastProps) => {
  const toast = useToast();

  useEffect(() => {
    console.log(message, title, type);
    if (message) toast.show(message, { type: type, data: { title: title } });
  }, [message, title, type]);

  return <></>;
};
*/

// Render
export const ToastRenderTypes = (toast: any, type: ToastType) => {
  return (
    <View style={styles.container(type)}>
      <View style={styles.textContainer()}>
        {toast.data.title && <Text style={styles.text(type, true)}>{toast.data.title}</Text>}
        <Text style={styles.text(type)}>{toast.message}</Text>
      </View>
      <TouchableOpacity onPress={() => toast.onHide()} style={styles.closeContainer(type)}>
        <Text style={styles.closeX()}>X</Text>
      </TouchableOpacity>
    </View>
  );
};

const backgroundColor: any = {
  normal: '#f2f2f2',
  default: '#f2f2f2',
  info: '#d9e7ff',
  success: '#eaf5dc',
  error: '#f9d7d7',
  danger: '#f9d7d7',
  warning: '#fff3d9',
};
const borderColor: any = {
  normal: '#8c8c8c',
  default: '#8c8c8c',
  info: '#4b81ff',
  success: '#5c9d45',
  error: '#e84545',
  danger: '#e84545',
  warning: '#ffa500',
};
const textColor: any = {
  normal: '#444444',
  default: '#444444',
  info: '#4b81ff',
  success: '#5c9d45',
  error: '#e84545',
  danger: '#e84545',
  warning: '#ffa500',
};

const styles = {
  container: (type: ToastType): ViewStyle => {
    return {
      flexDirection: 'row',
      backgroundColor: backgroundColor[type],
      paddingHorizontal: 16,
      paddingVertical: 8,
      borderRadius: 12,
      borderColor: borderColor[type],
      borderWidth: 1,
      marginVertical: 5,
    };
  },
  textContainer: (): ViewStyle => {
    return {
      flexDirection: 'column',
    };
  },
  text: (type: ToastType, isTitle: boolean = false): TextStyle => {
    return {
      color: textColor[type],
      paddingTop: 2,
      marginRight: 24,
      fontWeight: isTitle ? 'bold' : 'normal',
    };
  },
  closeContainer: (type: ToastType): ViewStyle => {
    return {
      marginLeft: 'auto',
      width: 25,
      height: 25,
      borderRadius: 12.5,
      backgroundColor: borderColor[type],
      justifyContent: 'center',
      alignItems: 'center',
    };
  },
  closeX: (): TextStyle => {
    return {
      color: '#fff',
      fontWeight: '500',
      marginBottom: 2.5,
    };
  },
};
