import { StyleSheet } from 'react-native';
import { themeConfig } from './themeConfig';

const themeStyle = StyleSheet.create({
  siteContainer: {
    width: '100%',
    minWidth: 360,
    flex: 1,
  },
  pageContainer: {
    height: 'auto',
    flex: 1,
  },
  pageTitle: {
    ...themeConfig.text.title,
    color: themeConfig.colors.darkGrey,
  },
});

export const theme = {
  ...themeStyle,
  colors: themeConfig.colors,
  text: themeConfig.text,
  settings: themeConfig.settings,
};
