/**
 * @param {ChildNode} children - child nodes
 * @param {ButtonDirectionType} direction - container type > horizontal / vertical
 * @param {StyleSheet} containerStyle - container style
 * @returns {React.ReactNode}
 */
import React, { createContext } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { themeButton } from '../../styles';

type ButtonDirectionType = 'vertical' | 'horizontal';
type ButtonContainerProps = {
  children?: React.ReactNode | React.ReactNode;
  direction?: ButtonDirectionType;
  containerStyle?: StyleProp<ViewStyle>;
};

export type ButtonContainerCtxType = {
  numberOfSibling: number;
};
export const ButtonContainerCtx = createContext<ButtonContainerCtxType | null>(null);

export const ButtonContainer = ({ direction = 'vertical', containerStyle, children }: ButtonContainerProps) => {
  const count = React.Children.count(children);
  const ctx: ButtonContainerCtxType = { numberOfSibling: direction === 'horizontal' ? count : 1 };

  return (
    <ButtonContainerCtx.Provider value={ctx}>
      <View
        style={[
          direction === 'vertical' ? themeButton.containerVertical : themeButton.containerHorizontal,
          containerStyle,
        ]}
      >
        {children}
      </View>
    </ButtonContainerCtx.Provider>
  );
};
