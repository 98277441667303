import React from 'react';
import { View, Image } from 'react-native';
import { BLink } from '..';
import logo from '../../assets/images/iss_logo_dark.webp';
import { themeHeader } from '../../styles';

export const Header = () => {
  return (
    <>
      <View style={themeHeader.container}>
        <>
          <View style={themeHeader.leftContainer}>
            <Image source={{ uri: logo }} style={themeHeader.appLogo} />
            <BLink to={{ screen: 'home' }} style={themeHeader.appTitle}>
              Jira Logs
            </BLink>
          </View>
        </>
      </View>
    </>
  );
};
