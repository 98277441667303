import { StyleSheet } from 'react-native';

export const detaultFont = 'Helvetica, sans-serif, Cambria, Cochin, Georgia, Times, Times New Roman, serif';

const themeColor: any = {
  blue: '#38539a',
  lightBlue: '#ADD8E6',
  white: '#fff',
  black: '#2c2c2c',
  text: '#5E6C84',
  menu: '#5E6C84',
  textDarker: '#222',
  darkGrey: '#333',
  grey: '#707070',
  red: '#e6203a',
  lightLine: '#f0f0f0',
  lightGrey: '#e4e4e4',
  lightPurple: '#f1f2ff',
  darkPurple: '#38539a',
  brown: '#a65523',
  warning: '#d91734',
  error: '#b20000',
  shadow: '#c0c0c0',
  link: '#007aff',
  background: '#f0f0f0',
  backgroundRegistered: '#fff1e8',
  backgroundBlue: '#38539a',
  backgroundLightBlue: '#f1f2ff',
  borderLightGrey: '#d6d6d6',
  yellow: '#FFFF00',
  favColor: '#0074D9',
  menuIconHighlighted: '#0074D9',
  menuIcon: '#707070',
};

const themeSetting: any = {
  fontFamily: detaultFont,
  // http://www.standardista.com/px-to-rem-conversion-if-root-font-size-is-16px/
  fontSize: {
    normal: 14,
    link: 14,
    button: 14,
    input: 14,
  },
  cardRadius: 12,
  cardBoxShadow: {
    shadowColor: themeColor.shadow,
    shadowOffset: {
      width: -1,
      height: 3,
    },
    elevation: 5,
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  borderRadius: 12,
  headerHeight: {
    mobile: 70,
    desktop: 70,
  },
  mobileContentOffset:
    //70 /* Header */ +
    45 /* Footer/Menu */ +
    16 * 2 /* Page.Content padding */ +
    28 +
    15 +
    18 /* Title height, padding top/bottom/margin */,
  mobileContentOffsetWithoutPageTitle:
    //70 /* Header */ +
    45 /* Footer/Menu */ + 16 * 2 /* Page.Content padding */ + 0,
  mobileMenuHeight: 45,
  screenWidth: {
    general: 1290,
    lg: 1280,
    md: 900,
  },
};

const textStyle = StyleSheet.create({
  h1: {
    fontFamily: detaultFont,
    fontSize: 32,
    fontWeight: 'bold',
    marginBottom: 16,
  },
  h2: {
    fontFamily: detaultFont,
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 12,
  },
  h3: {
    fontFamily: detaultFont,
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  title: {
    fontFamily: detaultFont,
    fontSize: 28,
    fontWeight: 'bold',
  },
  subTitle: {
    fontFamily: detaultFont,
    fontSize: 16,
    fontWeight: 'bold',
  },
  pageTitle: {
    fontFamily: detaultFont,
    fontSize: 24,
    fontWeight: 'bold',
    color: themeColor.textDarker,
    marginBottom: 15,
  },
  normal: {
    fontFamily: detaultFont,
    fontSize: 14,
  },
  bold: {
    fontFamily: detaultFont,
    fontSize: 14,
    fontWeight: 'bold',
  },
  italic: {
    fontFamily: detaultFont,
    fontSize: 14,
    fontStyle: 'italic',
  },
  link: {
    fontFamily: detaultFont,
    fontSize: 14,
    color: themeColor.link,
    position: 'relative',
  },
  menu: {
    fontFamily: detaultFont,
    fontSize: 14,
    color: themeColor.menu,
  },
});

export const themeConfig = {
  colors: themeColor,
  text: textStyle,
  settings: themeSetting,
};
