import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
// Components
import { Home } from '../modules/home';
// Auth
//import { Login, Logout, ForgetPassword, UserRegistration } from '../modules/auth';

const HomeStack = createStackNavigator();

// First level menu
export const AppLinksConfig = {
  home: 'home',
};

export const AppRoutes = () => (
  <HomeStack.Navigator
    screenOptions={{
      headerShown: false,
      cardStyle: {
        flex: 1,
      },
    }}
  >
    <HomeStack.Screen name="home" component={Home} options={{ title: `${process.env.REACT_APP_TITLE} - Home` }} />
    {/* Login/Auth */}
  </HomeStack.Navigator>
);
