import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { JiraStateModel } from '../../types/jiraModels';

const initialDemoState: JiraStateModel = {
  loaded: false,
  tasks: [],
  userLoaded: false,
  users: [],
  triggeredLoaded: false,
  triggeredBy: [],
};

const jiraSlice = createSlice({
  name: 'jira',
  initialState: initialDemoState,
  reducers: {
    setTasks(state, action: PayloadAction<any>) {
      state.tasks = action.payload;
      state.loaded = true;
    },
    setUser(state, action: PayloadAction<any>) {
      state.users = action.payload;
      state.userLoaded = true;
    },
    setTriggeredBy(state, action: PayloadAction<any>) {
      state.triggeredBy = action.payload;
      state.triggeredLoaded = true;
    },
    clear(state) {
      state.tasks = [];
      state.loaded = false;
      state.userLoaded = false;
      state.users = [];
      state.triggeredLoaded = false;
      state.triggeredBy = [];
    },
  },
});

export default jiraSlice;
