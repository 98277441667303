import AsyncStorage from '@react-native-async-storage/async-storage';

class localStorageClass {
  // https://react-native-async-storage.github.io/async-storage/docs/api
  async setItem(key: string, value: string) {
    try {
      await AsyncStorage.setItem(key, value);
    } catch (e) {
      // saving error
      console.error(`Error on set storageItem ${key}`);
    }
  }

  async getItem(key: string) {
    try {
      const value = await AsyncStorage.getItem(key);
      return value;
    } catch (e) {
      // error reading value
      console.error(`Error on get storageItem ${key}`);
    }
  }

  async removeItem(key: string) {
    try {
      await AsyncStorage.removeItem(key);
    } catch (e) {
      // remove error
      console.error(`Error on remove storageItem ${key}`);
    }
  }
}

const localStorage = new localStorageClass();
export default localStorage;
