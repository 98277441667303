/**
 * @param {ChildNode} children - child nodes
 * @param {Function} onPress - action when clicked
 * @param {boolean} [disabled] - is disabled button
 * @param {StyleSheet} [containerStyle] - outer container style
 * @param {StyleSheet} [buttonStyle] - button style
 * @param {string} [colorName] - predefined color
 */
import React, { useEffect, useState, useContext } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { themeButton } from '../../styles';
import { FormContainerCtx, FormContainerCtxType } from './FormContainer';
import { ButtonContainerCtx, ButtonContainerCtxType } from './ButtonContainer';

export type ButtonProps = {
  children: React.ReactNode;
  disabled?: boolean;
  onPress: any;
  containerStyle?: any;
  buttonStyle?: any;
  colorName?: string;
};

export const Button = ({
  children,
  onPress,
  disabled = false,
  containerStyle = {},
  buttonStyle = {},
  colorName = '',
}: ButtonProps) => {
  // Context
  const buttonContainerCtx: ButtonContainerCtxType | null = useContext(ButtonContainerCtx);
  const formContainerCtx: FormContainerCtxType | null = useContext(FormContainerCtx);

  // useState
  const [extraStyleName, setExtraStyleName] = useState<string>('');
  const [numberOfSibling, setSibling] = useState(1);

  // useEffect
  useEffect(() => {
    // for future use
    console.log(formContainerCtx);
  }, []);

  useEffect(() => {
    setSibling(buttonContainerCtx?.numberOfSibling || 1);
  }, [buttonContainerCtx?.numberOfSibling]);

  useEffect(() => {
    if (disabled) {
      setExtraStyleName('disabled');
    }
  }, [disabled]);

  return (
    <View style={StyleSheet.compose(themeButton.buttonContainer(numberOfSibling), containerStyle)}>
      <TouchableOpacity onPress={onPress} disabled={disabled}>
        <Text
          style={[themeButton.button, themeButton[colorName] || {}, themeButton[extraStyleName] || {}, buttonStyle]}
        >
          {children}
        </Text>
      </TouchableOpacity>
    </View>
  );
};
