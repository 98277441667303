import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PortalModel } from '../../types';

const initialState: PortalModel = {
  isLoading: false,
  headerProfileMenuOpen: false,
  breakpoint: 'lg',
  isSmallScreen: false,
  width: 1200,
  height: 1000,
  mainRoute: 'home',
};
/**
 * Breakpoint definitions
 * xs < 576
 * sm >= 576
 * md >= 768
 * lg >= 992
 * xl >= 1200
 */

const portalSlice = createSlice({
  name: 'portal',
  initialState: initialState,
  reducers: {
    setPortal(state, action: PayloadAction<any>) {
      state = action.payload.data;
    },

    setPortalProfileMenu(state, action: PayloadAction<any>) {
      state.headerProfileMenuOpen = action.payload.data;
    },

    setBreakpoint(state, action: PayloadAction<any>) {
      state.breakpoint = action.payload.breakpoint;
      state.width = action.payload.width;
      state.height = action.payload.height;
      state.isSmallScreen = ['xs', 'sm'].includes(state.breakpoint);
    },

    setRoute(state, action: PayloadAction<any>) {
      state.mainRoute = action.payload.screen;
    },

    setLoading(state, action: PayloadAction<any>) {
      state.isLoading = action.payload.isLoading;
    },

    clear(state) {
      state = initialState;
    },
  },
});

export default portalSlice;
