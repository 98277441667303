import { configureStore } from '@reduxjs/toolkit';
//import demoSlice from './demo/demoSlice';
import portalSlice from './portal/portalSlice';
//import userSlice from './user/userSlice';
import jiraSlice from './jira/jiraSlice';

const rootReducer = {
  portal: portalSlice.reducer,
  //  user: userSlice.reducer,
  jira: jiraSlice.reducer,
};

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
