import { StyleSheet } from 'react-native';

export const themePage = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    flexDirection: 'column',
  },
  contentContainer: {
    paddingTop: 18,
    flexDirection: 'column',
  },
  contentContainerNoTitle: {
    flexDirection: 'column',
  },
});
