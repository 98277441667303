import { Platform } from 'react-native';
import jwt_decode from 'jwt-decode';
import { UserModel, UserStateModel } from '../types';
import localStorage from './localStorage';

export const doLogout = async (webRedirect: boolean = false) => {
  console.log('Logout system, clear data');
  // clear localStorage
  await localStorage.removeItem('appToken');
  await localStorage.removeItem('exp');
  await localStorage.removeItem('userName');

  if (Platform.OS === 'web' && webRedirect) {
    window.location.href = '/';
  }
};

export const isTokenValid = async (token: string = '') => {
  if (!token) {
    token = (await localStorage.getItem('appToken')) || '';
  }

  if (token) {
    const decoded: UserModel = jwt_decode(token);

    if (decoded?.exp) {
      return new Date().getTime() < decoded.exp * 1000;
    }
    return false;
  }
  return false;
};

export const validateLogon = async (rtUser: UserStateModel | null) => {
  return (
    rtUser &&
    (rtUser.loginStatus === true || rtUser.validateStatus === true || (await isTokenValid())) &&
    rtUser.user?._id !== undefined &&
    rtUser.user?._id !== 'undefined' &&
    rtUser.user?._id !== ''
  );
};
