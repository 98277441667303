import { StyleSheet } from 'react-native';
import { themeConfig } from './themeConfig';

export const themeMenuContent = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    flexDirection: 'row',
  },
  containerSM: {
    width: '100%',
    height: '100%',
    flexDirection: 'column',
  },
  menuContainer: {
    flex: 1,
    width: '20%',
    minWidth: 200,
    maxWidth: 300,
    height: '100%',
    paddingTop: 15,
    borderRightWidth: 2,
    borderRightColor: themeConfig.colors.borderLightGrey,
  },
  menuContainerSM: {
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: themeConfig.colors.white,
    position: 'absolute',
    top: 36,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 99,
  },
  menuButton: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    fontSize: 16,
    fontWeight: 'bold',
    color: themeConfig.colors.grey,
    backgroundColor: themeConfig.colors.white,
    borderColor: themeConfig.colors.borderLightGrey,
    borderTopWidth: 1,
    borderBottomWidth: 1,
  },
  menuButtonIcon: {
    marginRight: 12,
  },
  contentContainer: {
    flex: 2,
    padding: 0,
  },
  contentTitle: {
    ...themeConfig.text.title,
  },
});
