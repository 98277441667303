/**
 * @param {string} id - field id
 * @param {string} [label] - field label
 * @param {any} [value] - input default value
 * @param {string} type - Referring to data type,
 *    HTMLInput - text(default), email, number, date, password, tel, range, time, url, week, search
 *    HTMLSelect - select
 * @param {string} keyboardType - use for native app
 *    'email-address' - default option if type=email
 *        This keyboard type is designed for inputting email addresses and includes letters (a-z), numbers (0-9), and special characters like @ and .
 *    'numeric' - default option if type=number
 *        This keyboard type will allow users to input only numbers (0-9), plus and minus sign, and decimal point (.), but will not include symbols like # or *.
 *    'decimal-pad' -
 *        This keyboard type is similar to the numeric keyboard, but it also includes a decimal point (.) for inputting decimal numbers.
 *    'number-pad'  -
 *        This keyboard type includes numbers (0-9) and arithmetic operators like +, -, *, and /, but does not include a decimal point (.) or other symbols.
 *    'phone-pad' - default option if type=tel
 *        This keyboard type is designed for inputting phone numbers and includes numbers (0-9), plus and minus signs, and special characters like # and *.
 *    'url' - default option if type=url
 *        This keyboard type is designed for inputting URLs and includes letters (a-z), numbers (0-9), and special characters like ., /, and :.
 *    'numbers-and-punctuation' -
 *        The keyboard displays numbers and punctuation characters.
 *    'name-phone-pad' -
 *        The keyboard displays a phone pad, which includes numbers, letters, and special characters commonly used in names (e.g., hyphen, apostrophe).
 *    'ascii-capable' -
 *        The keyboard displays ASCII characters, which includes numbers, letters, and punctuation.
 *    'twitter' -
 *        The keyboard displays a Twitter-style keyboard, which includes a hash symbol (#) and an at symbol (@) for mentioning users.
 *    'web-search' - default option if type=search
 *        The keyboard displays a search keyboard, which includes a search button.
 * @param {boolean} [multiline] - allow multiline (HTML TextArea)
 * @param {Array} [dataSource] - select list items
 * @param {StyleSheet} [style] - additional style
 * @param {string} [placeholder] - field placeholder
 * @param {string} [desc] - additional description
 * @returns {React.ReactNode}
 * */

import React, { useState, useEffect, useContext, forwardRef, useImperativeHandle, useRef, Ref, RefObject } from 'react';
import { Platform, TextInput, View, Text, KeyboardTypeOptions } from 'react-native';
//import { Picker } from '@react-native-picker/picker';
import { themeForm, textInputHMTLStyle } from '../../styles';
import { yupErrorsObject } from '../../utils/yup';
import { FormContainerCtx, FormContainerCtxType } from './FormContainer';
import { FieldGroupContainerCtx, FieldGroupContainerCtxType } from './FieldGroupContainer';

const isNative = Platform.OS !== 'web';

export type InputRefProps = {
  value?: string;
  setValue?: any;
};

type InputType =
  | ''
  | 'text'
  | 'email'
  | 'number'
  | 'date'
  | 'password'
  | 'tel'
  | 'range'
  | 'time'
  | 'url'
  | 'week'
  | 'search'
  | 'select'
  | 'radio'
  | 'checkbox';

interface InputProps {
  label?: string;
  id?: string;
  name?: string; // to remove
  type?: InputType; // type first, then specific keyboardType for native
  keyboardType?: KeyboardTypeOptions;
  multiline?: boolean; // textarea
  value?: any;
  dataSource?: any[];
  style?: object;
  placeholder?: string;
  desc?: string;

  // props
  required?: boolean;
  readOnly?: boolean;
  showPleaseSelect?: boolean;

  // events
  onChange?: Function;

  // yup schema validation
  schemaObject?: any;
  schemaContext?: any;

  // others
  [x: string]: any;
}

export const Input = forwardRef(
  (
    {
      label = '',
      id = '',
      name = '',
      type = 'text',
      keyboardType = 'default',
      multiline = false,
      value = '',
      dataSource = [],
      style = {},
      placeholder = '',
      desc = '',

      // props
      required = false,
      readOnly = false,
      showPleaseSelect = true,

      // events
      onChange = () => {},

      // yup schema validation
      schemaObject,
      schemaContext,

      ...restProps
    }: InputProps,
    ref: Ref<InputRefProps>, //TextInput | HTMLInputElement |
  ) => {
    // For future use
    const formContainerCtx: FormContainerCtxType | null = useContext(FormContainerCtx);
    const fieldGroupContainerCtx: FieldGroupContainerCtxType | null = useContext(FieldGroupContainerCtx);

    const [inputValue, setInputValue] = useState<any>('');
    const [inputKeyboardType, setInputKeyboardType] = useState<KeyboardTypeOptions>('default');
    const [schemaValue, setSchemaValue] = useState<object>({});
    const [schemaErrorMsg, setSchemaErrorMsg] = useState('');
    const [isReadOnly, setReadOnly] = useState<boolean>(false);

    const [width, setWidth] = useState(0);

    const inputRef = useRef<unknown>(null);

    useImperativeHandle(ref, () => {
      return {
        value: !isNative ? (inputRef.current as HTMLInputElement | HTMLSelectElement)?.value : '',
        setValue: (val: string) => setInputValue(val),
      };
      /*if (isNative) {
        return inputRef.current;
      } else {
        return inputRef.current as RefObject<InputType>;
      }*/
    });

    useEffect(() => {
      if (!name) name = id;

      // type & keyboardType
      if (keyboardType === 'default' && type !== 'text') {
        switch (type) {
          case 'email':
            setInputKeyboardType('email-address');
            break;
          case 'number':
            setInputKeyboardType('numeric');
            break;
          case 'url':
            setInputKeyboardType('url');
            break;
          case 'search':
            setInputKeyboardType('web-search');
            break;
          case 'tel':
            setInputKeyboardType('phone-pad');
            break;
        }
      } else {
        setInputKeyboardType(keyboardType);
      }

      // Readonly
      setReadOnly(readOnly);

      console.log(id, value);
    }, []);

    useEffect(() => {
      if (formContainerCtx?.readonly) {
        setReadOnly(formContainerCtx.readonly);
      } else if (fieldGroupContainerCtx?.readonly) {
        setReadOnly(fieldGroupContainerCtx.readonly);
      }
    }, [formContainerCtx?.readonly, fieldGroupContainerCtx?.readonly]);

    useEffect(() => {
      if (id) {
        // For validation
        let obj: any = {};
        obj[id] = value;
        setSchemaValue(obj);
      }
      setInputValue(value);
    }, [value]);

    useEffect(() => {
      // get validation
      if (id && schemaObject) {
        schemaObject
          .validate(schemaValue, { abortEarly: false, context: schemaContext || {} })
          .then(() => {
            console.log('pass');
          })
          .catch((err: any) => {
            let yer: any = yupErrorsObject(err);
            //console.log(yer, id, schemaContext);
            setSchemaErrorMsg(yer.find((y: any) => y.field === id)?.error || '');
          });
      }
    }, [schemaValue]);

    const localOnchange = (changedValue: any) => {
      let curValue: any = '';
      if (isNative) {
        curValue = changedValue;
      } else {
        curValue = changedValue.target.value;
      }

      if (id) {
        setSchemaValue({
          [id]: curValue,
        });
      }

      if (type === 'select') {
        setInputValue(curValue || '');
      }

      if (onChange) {
        onChange(curValue);
      }
    };

    const handleLayout = (event: any) => {
      //const { width } = event.nativeEvent.layout;
      setWidth(event.nativeEvent.layout.width);
    };

    return (
      <View style={themeForm.fieldRow}>
        <Text style={themeForm.fieldLabel}>{label}</Text>
        <View style={themeForm.fieldContainer} onLayout={handleLayout}>
          {isNative /* ToDo: Revise Logic */ &&
            [
              'default',
              'email-address', // This keyboard type is designed for inputting email addresses and includes letters (a-z), numbers (0-9), and special characters like @ and .
              'numeric', // 0-9,-. //  This keyboard type will allow users to input only numbers (0-9), plus and minus sign, and decimal point (.), but will not include symbols like # or *.
              'decimal-pad', // 0-9. // This keyboard type is similar to the numeric keyboard, but it also includes a decimal point (.) for inputting decimal numbers.
              'number-pad', // 0-9,-. // This keyboard type includes numbers (0-9) and arithmetic operators like +, -, *, and /, but does not include a decimal point (.) or other symbols.
              'phone-pad', // This keyboard type is designed for inputting phone numbers and includes numbers (0-9), plus and minus signs, and special characters like # and *.
              'url', // This keyboard type is designed for inputting URLs and includes letters (a-z), numbers (0-9), and special characters like ., /, and :.
              'numbers-and-punctuation', // The keyboard displays numbers and punctuation characters.
              'name-phone-pad', // The keyboard displays a phone pad, which includes numbers, letters, and special characters commonly used in names (e.g., hyphen, apostrophe).
              'ascii-capable', // The keyboard displays ASCII characters, which includes numbers, letters, and punctuation.
              'twitter', // The keyboard displays a Twitter-style keyboard, which includes a hash symbol (#) and an at symbol (@) for mentioning users.
              'web-search', // The keyboard displays a search keyboard, which includes a search button.

              'password',
              'date',
              'color',
              'datetime-local',
              'month',
              'range',
              'time',
              'url',
              'week',
            ].includes(keyboardType) && (
              <TextInput
                style={themeForm.textInput}
                keyboardType={inputKeyboardType}
                defaultValue={inputValue}
                placeholder={placeholder}
                onChangeText={localOnchange}
                // appearance
                editable={!isReadOnly}
                // reference
                ref={inputRef as RefObject<TextInput>}
                {...restProps}
              />
            )}
          {!isNative && (
            <>
              {[
                'text',
                'email',
                'tel',
                'password',
                'number',
                'date',
                'color',
                'datetime-local',
                'month',
                'range',
                'time',
                'url',
                'week',
              ].includes(type) && (
                <input
                  type={type}
                  id={id}
                  name={name}
                  defaultValue={inputValue}
                  placeholder={placeholder}
                  onChange={localOnchange}
                  // appearance
                  readOnly={isReadOnly}
                  required={required}
                  style={textInputHMTLStyle(width, isReadOnly)}
                  // reference
                  ref={inputRef as RefObject<HTMLInputElement>}
                  {...restProps}
                />
              )}
              {['select'].includes(type) && (
                <select
                  id={id}
                  name={name}
                  value={inputValue}
                  //defaultValue={inputValue}
                  placeholder={placeholder}
                  onChange={localOnchange}
                  // appearance
                  disabled={readOnly}
                  required={required}
                  style={themeForm.selectHTML}
                  // reference
                  ref={inputRef as RefObject<HTMLSelectElement>}
                  {...restProps}
                >
                  {showPleaseSelect && <option value={''}> -- please select -- </option>}
                  {dataSource.map((row: any, idx: number) => (
                    <option value={row.value} key={idx}>
                      {row.label}
                    </option>
                  ))}
                </select>
              )}
            </>
          )}
          {schemaErrorMsg !== '' && <Text style={themeForm.inputError}>{schemaErrorMsg}</Text>}
          {desc !== '' && <Text style={themeForm.inputDescription}>{desc}</Text>}
        </View>
      </View>
    );
  },
);
