import { StyleSheet } from 'react-native';
import { themeConfig } from './themeConfig';

const themeHeaderStyle = StyleSheet.create({
  container: {
    padding: 16,
    height: 70,
    zIndex: 1,
    flexDirection: 'row',
    backgroundColor: themeConfig.colors.white,
  },
  containerSM: {
    padding: 0,
    height: 0,
    zIndex: 1,
    flexDirection: 'row',
    backgroundColor: themeConfig.colors.white,
  },
  leftContainer: {
    width: 320,
    flexDirection: 'row',
  },
  middleContainer: {
    marginLeft: 10,
    flexDirection: 'row',
    paddingTop: 18,
  },
  rightContainer: {
    position: 'absolute',
    right: 10,
    paddingTop: 18,
    flexDirection: 'row',
  },

  appLogo: {
    width: 150,
    height: 32,
    resizeMode: 'cover',
  },
  appTitle: {
    marginLeft: 10,
    fontSize: 16,
    width: 200,
    color: themeConfig.colors.black,
    paddingTop: 17,
  },

  menuItem: {
    color: themeConfig.colors.black,
    fontSize: 14,
    marginLeft: 12,
    marginRight: 12,
  },
  menuDivider: {
    marginTop: -3,
  },

  menuItemIcon: {
    color: themeConfig.colors.menuIcon,
    width: 24,
  },

  iconSelected: {
    color: themeConfig.colors.menuIconHighlighted,
  },
});

const dropdownMenuStyle = StyleSheet.create({
  container: {
    position: 'relative',
    zIndex: 1,
  },
  menuButton: {
    fontSize: 16,
    color: themeConfig.colors.black,
    paddingTop: 3,
  },
  menuList: {
    position: 'absolute',
    top: '120%',
    right: 0,
    width: 250,
    backgroundColor: themeConfig.colors.white,
    borderWidth: 1,
    borderColor: themeConfig.colors.lightGrey,
    borderRadius: 5,
    padding: 0,
    paddingBottom: 5,
    zIndex: 999,
  },
  menuListHover: {
    backgroundColor: themeConfig.colors.black,
  },
  menuItem: {
    color: themeConfig.colors.black,
    paddingVertical: 5,
    paddingHorizontal: 10,
    marginVertical: 2,
  },
  menuItemHeader: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    marginVertical: 5,
    flexDirection: 'row',
  },
  menuItemHeaderText: {
    color: themeConfig.colors.black,
    marginTop: 6,
    marginLeft: 8,
  },
  menuItemText: {
    ...themeConfig.text.menu,
  },
  menuItemHover: {
    backgroundColor: themeConfig.colors.grey,
  },
  menuDivider: {
    height: 1,
    backgroundColor: themeConfig.colors.lightGrey,
    marginVertical: 0,
  },
});

const bottomMenu = StyleSheet.create({
  container: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    flexDirection: 'row',
    zIndex: 999,
    backgroundColor: themeConfig.colors.white,
    paddingTop: 4,
    paddingBottom: 4,
    //borderTopColor: themeConfig.colors.lightLine,
    //borderTopWidth: 1,
    shadowColor: themeConfig.colors.shadow,
    shadowOffset: {
      width: -1,
      height: 3,
    },
    elevation: 5,
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  menuItem: {
    padding: 8,
    flex: 1,
    color: themeConfig.colors.black,
    fontSize: 14,
    marginHorizontal: 'auto',
    textAlign: 'center',
  },
  menuItemIcon: {
    color: themeConfig.colors.grey,
    width: 48,
  },
});

export const themeHeader = {
  ...themeHeaderStyle,
  dropdown: dropdownMenuStyle,
  bottom: bottomMenu,
};
