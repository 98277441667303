import React from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';

const styles = StyleSheet.create({
  fullScreen: {
    flex: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  container: {
    zIndex: 1000,
  },
  backgroundContainer: {
    backgroundColor: '#e0e0e0',
    opacity: 0.35,
  },
  loadingContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export const AppLoading = () => (
  <View style={[styles.fullScreen, styles.container]}>
    <View style={[styles.fullScreen, styles.backgroundContainer]} />
    <View style={[styles.fullScreen, styles.loadingContainer]}>
      <ActivityIndicator size="large" />
    </View>
  </View>
);
