/**
 * @param {ChildNode} children - React Node contents
 * @param {abject|string} to - a Link parameters (screen, pathname) or string
 * @param {StyleSheet} [style] - additional style
 * @param {any} anypraram - other parameters
 * @returns {React.ReactNode}
 */
import React, { useEffect, useState } from 'react';
import { StyleSheet, StyleProp, TextStyle, Platform } from 'react-native';
import { Link } from '@react-navigation/native';
import { theme } from '../styles';

type propsType = {
  children: React.ReactNode;
  to: any;
  style?: StyleProp<TextStyle>;
  [x: string]: any;
};

export const BLink = ({ to, children, style, ...props }: propsType) => {
  const newStyle = StyleSheet.compose(theme.text.link, style);
  const isNative = Platform.OS !== 'web';
  const [finalTo, setTo] = useState<string | any>('');

  useEffect(() => {
    if ((typeof to === 'string' || isNative) && !to.pathname) {
      setTo(to);
    } else {
      if (to.screen.charAt('/') >= 0 || to.pathname) {
        setTo(to.pathname || `/${to.screen}`);
      } else {
        setTo(to);
      }
    }
  });

  return (
    <Link to={finalTo} {...props} style={newStyle}>
      {children}
    </Link>
  );
};
