/**
 * @param {ChildNode} children - child nodes
 * @param {StyleSheet} style - additional style
 * @param {any} anypraram - other parameters
 * @returns {React.ReactNode}
 */
import React from 'react';
import { StyleSheet, TextStyle, StyleProp } from 'react-native';
import { theme } from '../styles';
import { BText } from './BText';

type propsType = {
  children: React.ReactNode;
  style?: StyleProp<TextStyle>;
  [x: string]: any;
};

export const PageTitle = ({ children, style, ...props }: propsType) => {
  const newStyle = StyleSheet.compose(theme.text.pageTitle, style);
  return (
    <BText {...props} style={newStyle}>
      {children}
    </BText>
  );
};
