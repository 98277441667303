import axios from 'axios';
import './axios-interceptor';

export default {
  async getUser() {
    return await axios.get(`jira/users`);
  },
  async getTriggeredBy() {
    return await axios.get(`jira/triggeredBy`);
  },
  async getTasks(year: string, month: string, user?: string, triggeredBy?: string) {
    return await axios.get(`jira/list/?year=${year}&month=${month}&user=${user}&triggeredBy=${triggeredBy}`);
  },
};
