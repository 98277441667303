import { AnyAction, ThunkAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { JiraModel } from '../../types';
import jiraService from '../../services/jiraService';
import jiraSlice from './jiraSlice';

export const jiraActions = jiraSlice.actions;

export const fetchUsers = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async dispatch => {
    let users = await jiraService.getUser();
    let list = users.data.result
      .sort((a: any, b: any) => a.User.localeCompare(b.User))
      .map((u: any) => {
        return {
          label: u.User,
          value: u.User,
        };
      });
    dispatch(jiraActions.setUser(list));
  };
};

export const fetchTriggeredBy = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async dispatch => {
    let users = await jiraService.getTriggeredBy();
    let list = users.data.result
      .sort((a: any, b: any) => a.TriggeredBy.localeCompare(b.TriggeredBy))
      .map((u: any) => {
        return {
          label: u.TriggeredBy,
          value: u.TriggeredBy,
        };
      });
    dispatch(jiraActions.setTriggeredBy(list));
  };
};

export const fetchTasks = (
  year: string,
  month: string,
  user?: string,
  triggeredBy?: string,
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async dispatch => {
    let tasks = await jiraService.getTasks(year, month, user, triggeredBy);
    dispatch(
      jiraActions.setTasks(
        tasks.data.result.sort((a: JiraModel, b: JiraModel) => {
          return new Date(a.CreatedDate).getTime() - new Date(b.CreatedDate).getTime();
        }),
      ),
    );
  };
};
