/**
 * @param {ChildNode} children - child nodes
 * @param {string} [title] - optional title text
 * @param {StyleSheet} [style] - additional style
 * @param {StyleSheet} [titleStyle] - addtional style to title text
 * @param {boolean} [readonly] - is the fields readonly
 */
import React, { createContext } from 'react';
import { View } from 'react-native';
import { BText } from '../BText';
import { themeForm } from '../../styles';

type FieldGroupContainerProps = {
  title?: string;
  children: React.ReactNode;
  style?: any;
  titleStyle?: any;
  readonly?: boolean;
};

// Context, for future use
export type FieldGroupContainerCtxType = {
  readonly: boolean;
};
export const FieldGroupContainerCtx = createContext<FieldGroupContainerCtxType | null>(null);

export const FieldGroupContainer = ({
  title,
  children,
  style,
  titleStyle,
  readonly = false,
}: FieldGroupContainerProps) => {
  const ctx: FieldGroupContainerCtxType = { readonly: readonly };

  return (
    <FieldGroupContainerCtx.Provider value={ctx}>
      <View style={[themeForm.fieldGroupContainer, style]}>
        <BText style={[themeForm.fieldGroupTitle, titleStyle]}>{title}</BText>
        {children}
      </View>
    </FieldGroupContainerCtx.Provider>
  );
};
