/**
 * @param {string} [title] - page title
 * @param {ChildNode} [children] - child nodes
 * @param {StyleSheet} [style] - additional style
 * @param {StyleSheet} [contentStyle] - style for content are
 * @param {boolean} [useScrollView] - default true, to use parent scroll view.
 * @returns {React.ReactNode}
 */
import React from 'react';
import { ScrollView, View } from 'react-native';
import { useAppSelector } from '../hooks';
import { themePage } from '../styles';
import { PageTitle } from './PageTitle';

type pageProps = {
  title?: string;
  children?: React.ReactNode;
  style?: any;
  contentStyle?: any;
  useScrollView?: boolean;
};

type viewProps = {
  isScrollView: boolean;
  children?: React.ReactNode;
  [x: string]: any; // restProps
};
const MyView = ({ isScrollView, children, ...restProps }: viewProps) => {
  if (isScrollView) {
    return <ScrollView {...restProps}>{children}</ScrollView>;
  } else {
    return <View {...restProps}>{children}</View>;
  }
};

export const Page = ({ title, children, style, contentStyle, useScrollView = true }: pageProps) => {
  const rtPortal = useAppSelector(s => s.portal);

  return (
    <MyView
      isScrollView={useScrollView}
      style={[themePage.container, style, { marginBottom: rtPortal.isSmallScreen ? 50 : 0 }]}
    >
      <PageTitle>{title}</PageTitle>
      <View style={[title ? themePage.contentContainer : themePage.contentContainerNoTitle, contentStyle]}>
        {children}
      </View>
    </MyView>
  );
};
