import { themeConfig } from './themeConfig';

interface StyleType {
  [key: string]: any;
}

const buttonGeneralStyle = {
  position: 'relative',
  textAlign: 'center',
  fontSize: themeConfig.settings.fontSize.button,
  fontWeight: 'bold',
  height: '100%',
  width: '100%',
  lineHeight: 30,
  borderRadius: themeConfig.settings.borderRadius,
};

export const themeButton: StyleType = {
  // Buttons
  containerVertical: {
    width: '100%',
    marginVertical: 8,
    marginHorizontal: 0,
    flexDirection: 'column',
  },
  containerHorizontal: {
    width: 'auto',
    marginVertical: 8,
    marginHorizontal: -5,
    flexDirection: 'row',
    flex: 1,
  },

  buttonContainer: (numberOfBtn: number = 1) => {
    // no border; no color
    let margin = numberOfBtn > 1 ? 5 : 0;
    let width = numberOfBtn > 1 ? 'auto' : '100%';

    return {
      position: 'relative',
      height: '28',
      width: width,
      marginBottom: 10,
      marginRight: margin,
      marginLeft: margin,
      flex: 1,
    };
  },

  button: {
    ...buttonGeneralStyle,
    color: themeConfig.colors.white,
    backgroundColor: themeConfig.colors.black,
  },

  disabled: {
    ...buttonGeneralStyle,
    color: themeConfig.colors.white,
    backgroundColor: themeConfig.colors.lightGrey,
  },

  grey: {
    ...buttonGeneralStyle,
    color: themeConfig.colors.darkGrey,
    backgroundColor: themeConfig.colors.lightGrey,
  },
};
