import { AnyAction, ThunkAction } from '@reduxjs/toolkit';
import { PortalModel } from '../../types';
import { breakpointWidth } from '../../utils/helper';
import { RootState } from '../index';
import portalSlice from './portalSlice';

export const portalActions = portalSlice.actions;

export const setPortal = (data: PortalModel): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async dispatch => {
    dispatch(portalActions.setPortal({ data: data }));
  };
};

export const setRoute = (screen: string): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async dispatch => {
    dispatch(portalActions.setRoute({ screen: screen }));
  };
};

export const setPortalProfileMenuOpen = (toggle: boolean): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async dispatch => {
    dispatch(portalActions.setPortalProfileMenu({ data: toggle }));
  };
};

export const setLoading = (
  isLoading: boolean,
  fromScreen: string = '',
): ThunkAction<void, RootState, unknown, AnyAction> => {
  if (fromScreen) console.log(fromScreen, isLoading);
  return async dispatch => {
    dispatch(portalActions.setLoading({ isLoading: isLoading }));
  };
};

export const setBreakpoint = (
  screenWidth?: number,
  screenHeight?: number,
  breakpoint: string = 'lg',
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async dispatch => {
    if (screenWidth) {
      if (screenWidth >= breakpointWidth('xl')) {
        breakpoint = 'xl';
      } else if (screenWidth >= breakpointWidth('lg')) {
        breakpoint = 'lg';
      } else if (screenWidth >= breakpointWidth('md')) {
        breakpoint = 'md';
      } else if (screenWidth >= breakpointWidth('sm')) {
        breakpoint = 'sm';
      } else {
        breakpoint = 'xs';
      }
    }
    //console.log(breakpoint)
    dispatch(portalActions.setBreakpoint({ breakpoint: breakpoint, width: screenWidth, height: screenHeight }));
  };
};

export const clearPortal = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return dispatch => {
    dispatch(portalActions.clear());
  };
};
