/**
 * @param {ChildNode} children - child nodes
 * @param {string} [title] - form title
 * @param {StyleSheet} [style] - additional style to container
 * @param {boolean} [boolean] - is all fields readonly
 * @param {number} [width] - suggested width, might affected by breakpoint (or smaller screen size)
 */
import React, { useState, useEffect, createContext } from 'react';
import { View } from 'react-native';
import { breakpointWidth } from '../../utils/helper';
import { useAppSelector } from '../../hooks';
import { themeForm } from '../../styles';
import { FormTitle } from '.';

type FormContainerProps = {
  title?: string;
  children: React.ReactNode;
  style?: any;
  width?: number;
  readonly?: boolean;
};

// Context, for future use
export type FormContainerCtxType = {
  readonly: boolean;
};
export const FormContainerCtx = createContext<FormContainerCtxType | null>(null);

export const FormContainer = ({ title, children, style, width = 500, readonly = false }: FormContainerProps) => {
  const rtProfile = useAppSelector(s => s.portal);
  const [formMaxWidth, setMaxWidth] = useState(width);
  const ctx: FormContainerCtxType = { readonly: readonly };

  useEffect(() => {
    /**
     * Breakpoint definitions
     * xs < 576
     * sm >= 576
     * md >= 768
     * lg >= 992
     * xl >= 1200
     */

    switch (rtProfile.breakpoint) {
      case 'xs':
      case 'sm':
        setMaxWidth(rtProfile.width - 32);
        break;
      case 'md':
        setMaxWidth(breakpointWidth('sm')); // 1 size smaller
        break;
      case 'lg':
        setMaxWidth(breakpointWidth('md')); // 1 size smaller
        break;
      case 'xl':
        setMaxWidth(breakpointWidth('lg')); // 1 size smaller
        break;
    }
  }, [rtProfile.breakpoint, rtProfile.width]);

  return (
    <FormContainerCtx.Provider value={ctx}>
      <View style={[themeForm.container, style, { width: width, maxWidth: formMaxWidth }]}>
        <FormTitle>{title}</FormTitle>
        {children}
      </View>
    </FormContainerCtx.Provider>
  );
};
