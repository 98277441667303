import React, { useEffect } from 'react';
import { NavigationContainer, LinkingOptions } from '@react-navigation/native';
import { Platform, View, Text, SafeAreaView, useWindowDimensions } from 'react-native';
import { ToastProvider } from 'react-native-toast-notifications';
import { ToastRenderTypes } from './components/';
import { useAppDispatch, useAppSelector } from './hooks';
import { AppRoutes, AppLinksConfig } from './routes';
import { Header } from './components/';
import { setBreakpoint } from './store/portal/portalActions';
import { theme } from './styles/theme';
import { AppLoading } from './components/';

/* eslint-disable @typescript-eslint/no-unused-vars */
const isNative = Platform.OS !== 'web';

const App = () => {
  const { width, height } = useWindowDimensions();

  const dispatch = useAppDispatch();
  const [rtPortal] = useAppSelector(s => [s.portal]);

  useEffect(() => {
    // update screen dimension
    dispatch(setBreakpoint(width, height));
  }, [width, height]);

  /*useEffect(() => {
    if (!isNative) {
      window.addEventListener('click', onBlankClick);
      // todo the same for native
    }

    validateAndUpdateSession();
  }, []);

  useEffect(() => {
    if (rtUser.validateStatus === false && rtUser.errorMsg) {
      doLogout();
      window.location.href = '/';
    }
  }, [rtUser.validateStatus]);
*/
  const linking: LinkingOptions<ReactNavigation.RootParamList> = {
    prefixes: [],
    config: {
      screens: { ...AppLinksConfig },
    },
  };

  /*const validateAndUpdateSession = async () => {
    // check token
    const appToken = (await localStorage.getItem('appToken')) || '';

    if (appToken && (await isTokenValid(appToken))) {
      dispatch(validateSession());
    }
  };

  const onBlankClick = () => {
    dispatch(setPortalProfileMenuOpen(false));
  };*/

  return (
    <ToastProvider
      placement="top"
      swipeEnabled={true}
      renderType={{
        normal: toast => ToastRenderTypes(toast, 'normal'),
        info: toast => ToastRenderTypes(toast, 'info'),
        success: toast => ToastRenderTypes(toast, 'success'),
        error: toast => ToastRenderTypes(toast, 'error'),
        warning: toast => ToastRenderTypes(toast, 'warning'),
      }}
      style={{ flex: 1 }}
    >
      <NavigationContainer linking={linking} fallback={<Text>Loading...</Text>}>
        <SafeAreaView style={theme.siteContainer}>
          <Header />
          <View style={theme.pageContainer}>
            <AppRoutes />
          </View>
          {rtPortal.isLoading && <AppLoading />}
        </SafeAreaView>
      </NavigationContainer>
    </ToastProvider>
  );
};

export default App;
