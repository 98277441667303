/**
 * @param {DataItem[]} data - data set
 * @param {DataGridColumn[]} columns - column set, refer to DataGridColumn for more details
 * @param {boolean} [showFilter] - to show filter, default is true
 * @param {DataGridActionProps} [actions] - action column for each row, refer to DataGridActionProps
 * @param {DataGridSortType} [defaultSort] - to set default sorting field & direction, refer to DataGridSortType
 * @param {Function} [onSelect] - the action to handle when clicked on the row
 * @param {Function} [onDoubleClick] - the action to handle when double clicked the row
 * @returns {React.ReactNode}
 *
 * DataGridColumn {
 *    @param {string} label - label of the column
 *    @param {string} key - field name
 *    @param {boolean} [sortable] - is the column sortable, default true
 *    @param {'center' | 'left' | 'right'} [align] - default aligh left, number aligh right
 *    @param {'text' | 'number' | 'date' | 'boolean'} [dataType] - data type, default is text
 *    @param {Function} [cellRender] - custom render function
 * }
 *
 * DataGridActionProps {
 *    @param {string} label - label of the action
 *    @param {Function} action - action to perform
 *    @param {boolean} [isDisabled] - is action disabled (this will disable for all records)
 *    @param {Function} [disable] - a function to decide if a record allow for the action
 * }
 *
 * DataGridSortType {
 *    @param {string} key - fieldname
 *    @param {'ascending' | 'descending'} direction - sort direction
 * }
 */

import React, { useEffect, useState } from 'react';
import { View, FlatList, TextInput, StyleSheet, Pressable } from 'react-native';
import { themeConfig } from '../../styles/themeConfig';
import { BText } from '../BText';

type DataGridCellAlign = 'center' | 'left' | 'right';
type DataGridDataType = 'text' | 'number' | 'date' | 'boolean';

export interface DataGridColumn {
  label: string;
  key: string;
  sortable?: boolean;
  align?: DataGridCellAlign;
  dataType?: DataGridDataType;
  cellRender?: Function;
  [x: string]: any;
}

interface DataGridDataItem {
  [key: string]: any;
}

type DataGridSortDirection = 'ascending' | 'descending';
interface DataGridSortType {
  key: string;
  direction: DataGridSortDirection;
}

interface DataGridActionProps {
  label: string;
  action: Function;
  isDisabled?: boolean;
  disable?: Function;
}

interface Props {
  data: DataGridDataItem[];
  columns: DataGridColumn[];
  showFilter?: boolean;
  actions?: DataGridActionProps[];
  defaultSort?: DataGridSortType;
  onSelect?: Function;
  onDoubleClick?: Function;
}

export const DataGrid: React.FC<Props> = ({
  data,
  columns,
  showFilter = true,
  actions = [],
  defaultSort,
  onSelect = () => null,
  onDoubleClick = () => null,
}) => {
  const [searchText, setSearchText] = useState('');
  const [sortConfig, setSortConfig] = useState<DataGridSortType>({
    key: '',
    direction: 'ascending',
  });
  const [processedColumns, setColumn] = useState<DataGridColumn[]>(columns);
  const [selectedRow, setSelectedRow] = useState(-1);
  const [columnLength, setColumnLength] = useState(columns.length);
  const [clickTimeout, setClickTimeout] = useState<any>(null);

  useEffect(() => {
    setColumnLength(columns.length + (actions.length ? 1 : 0));

    columns.map((col: DataGridColumn) => {
      if (!col.dataType) {
        col.dataType = 'text';
      }

      // if number, align right
      if (['number'].includes(col.dataType || '') && !col.align) {
        col.align = 'right';
      }

      if (col.sortable === null || col.sortable === undefined) {
        col.sortable = true;
      }

      return col;
    });

    setColumn(columns);
  }, [columns]);

  useEffect(() => {
    if (defaultSort) {
      setSortConfig(defaultSort);
    }
  }, [defaultSort]);

  const handleSort = (key: string) => {
    let direction: DataGridSortDirection = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedData = React.useMemo(() => {
    if (!sortConfig.key) {
      return data;
    }

    return [...data].sort((a: DataGridDataItem, b: DataGridDataItem) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  }, [data, sortConfig]);

  const filteredData = React.useMemo(() => {
    if (!searchText) {
      return sortedData;
    }

    return sortedData.filter((item: DataGridDataItem) =>
      Object.keys(item).some((key: string) => item[key].toString().toLowerCase().includes(searchText.toLowerCase())),
    );
  }, [sortedData, searchText]);

  const handleRowClick = (rowIndex: number, item: any) => {
    if (clickTimeout !== null) {
      clearTimeout(clickTimeout);
      setClickTimeout(null);
      // handle double click event
      onDoubleClick && onDoubleClick(item);
    } else {
      const timeout = setTimeout(() => {
        setClickTimeout(null);
        // handle single click event
        onSelect && onSelect(item);
        setSelectedRow(rowIndex);
      }, 300);
      setClickTimeout(timeout);
    }
  };

  const renderItem = (item: DataGridDataItem, rowIndex: number) => (
    <Pressable
      key={rowIndex}
      style={[
        styles.row,
        rowIndex % 2 === 0 ? styles.evenRow : styles.oddRow,
        selectedRow === rowIndex ? styles.selectedRow : null,
      ]}
      onPress={() => handleRowClick(rowIndex !== selectedRow ? rowIndex : -1, item)}
    >
      {processedColumns.map((column: DataGridColumn) => (
        <View key={column.key} style={[styles.cell, { flex: 1 / columnLength }]}>
          {column.cellRender && <>{column.cellRender(item)}</>}
          {!column.cellRender && <BText style={{ textAlign: column.align || 'left' }}>{item[column.key]}</BText>}
        </View>
      ))}
      {actions.filter(a => !a.isDisabled).length > 0 && (
        <View style={[styles.cell, { flex: 1 / columnLength }, styles.cellAction]}>
          {actions
            .filter(a => !a.isDisabled && ((a.disable && !a.disable(item)) || !a.disable))
            .map((actionItem, idx) => (
              <View>
                <BText key={idx} style={[themeConfig.text.link, styles.action]} onPress={() => actionItem.action(item)}>
                  {actionItem.label}
                </BText>
              </View>
            ))}
        </View>
      )}
    </Pressable>
  );

  return (
    <View style={styles.container}>
      {showFilter && (
        <View style={styles.searchBar}>
          <TextInput style={styles.searchInput} onChangeText={setSearchText} value={searchText} placeholder="Search" />
        </View>
      )}
      <FlatList
        data={filteredData}
        renderItem={({ item, index }) => renderItem(item, index)}
        keyExtractor={item => item.id}
        style={styles.gridContainer}
        ListHeaderComponent={() => (
          <View style={styles.header}>
            {processedColumns.map((column: DataGridColumn) => (
              <View style={[styles.cell, styles.headerCellContainer, { flex: 1 / columnLength }]}>
                <Pressable key={column.key} style={[styles.headerCell]} onPress={() => handleSort(column.key)}>
                  <BText style={styles.headerCellText}>{column.label}</BText>
                  {column.sortable && sortConfig && sortConfig.key === column.key && (
                    <BText style={styles.headerCellSort}>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</BText>
                  )}
                </Pressable>
              </View>
            ))}
            {actions.filter(a => !a.isDisabled).length > 0 && (
              <View style={[styles.cell, styles.headerCellContainer, { flex: 1 / columnLength }, styles.cellAction]}>
                <BText style={styles.headerCellText}>Actions</BText>
              </View>
            )}
          </View>
        )}
      />
    </View>
  );
};

const colors = {
  cellBorder: '#aaa',
  containerBorder: '#ccc',
  backgroundColor: '#fff',
  oddRow: '#DDEEFF',
  evenRow: '#BBCCEE',
  filterInput: '#eee',
  text: themeConfig.colors.black,
};

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: colors.containerBorder,
    borderRadius: 12,
    flex: 1,
    padding: 8,
    backgroundColor: colors.backgroundColor,
  },
  gridContainer: {
    borderColor: colors.cellBorder,
    borderBottomWidth: 1,
    borderRightWidth: 1,
  },
  searchBar: {
    backgroundColor: colors.filterInput,
    padding: 10,
  },
  searchInput: {
    backgroundColor: colors.filterInput,
    borderRadius: 10,
    padding: 5,
  },
  header: {
    flexDirection: 'row',
    borderColor: colors.cellBorder,
    borderBottomWidth: 1,
  },
  headerCellContainer: {
    flexDirection: 'column',
  },
  headerCell: {
    flexDirection: 'row',
    height: 22,
  },
  headerCellText: {
    fontWeight: 'bold',
    paddingTop: 4,
  },
  headerCellSort: {
    paddingTop: 4,
  },
  row: {
    flexDirection: 'row',
  },
  cell: {
    padding: 5,
    borderStyle: 'solid',
    borderColor: colors.cellBorder,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    flex: 1,
    color: colors.text,
  },
  evenRow: {
    backgroundColor: '',
  },
  oddRow: {
    backgroundColor: colors.oddRow,
  },
  selectedRow: {
    backgroundColor: colors.evenRow,
  },
  cellAction: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  action: {
    marginRight: 5,
  },
});
