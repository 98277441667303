/**
 * @param {ChildNode} children - React Node contents
 * @param {StyleSheet} [style] - additional style
 * @param {any} anypraram - other parameters
 * @returns {React.ReactNode}
 */
import React from 'react';
import { Text, StyleSheet, StyleProp, TextStyle } from 'react-native';
import { theme } from '../styles';

type propsType = {
  children: React.ReactNode;
  style?: StyleProp<TextStyle>;
  [x: string]: any;
};

export const BText = ({ children, style, ...props }: propsType) => {
  const newStyle = StyleSheet.compose(theme.text.normal, style);
  return (
    <Text {...props} style={newStyle}>
      {children}
    </Text>
  );
};
